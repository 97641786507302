import { MyEditor } from './components/myEditor'; 
import './style.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function App() {
  
  return (
      <div>    
        <MyEditor />
      </div>        
  );
}

export default App;
