import React, { Component } from 'react';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import * as XLSX from 'xlsx';
import { BsFillFileEarmarkArrowDownFill, BsFillFileEarmarkArrowUpFill, BsFillSendFill } from "react-icons/bs";
import axios from 'axios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import logo from './logo_vexia.png';

function generateGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

class MyEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      contacts: [],
      showGrid: false,
      showModal: false,
      modalMessage: ''
    };
 
    this.editorRef = React.createRef();
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const contacts = jsonData
        .slice(1)
        .map(row => ({
          nome: row[0] || '',
          telefone: row[1] || ''
        }))
        .filter(contact => contact.nome && contact.telefone);

      this.setState({ contacts });
    };

    reader.readAsArrayBuffer(file);
  };

  substituirMarcacoes(texto) {
    if (texto.includes("<strong>")) {
      texto = texto.replace(/<strong>(.*?)<\/strong>/g, ' *$1* ');
    }
    if (texto.includes("<em>")) {
      texto = texto.replace(/<em>(.*?)<\/em>/g, ' _$1_ ');
    }
    if (texto.includes("<ins>")) {
      texto = texto.replace(/<ins>(.*?)<\/ins>/g, ' ~$1~ ');
    }
    if (texto.includes("<p>")) {
      texto = texto.replace(/<p>/g, "\n").replace(/<\/p>/g, "\n");
    }
    return texto;
  }

  sendMessagesToContacts = async () => {
    const { editorState, contacts } = this.state;
    const content = editorState.getCurrentContent();

    if (!content.hasText()) {
      this.showModal("O editor está vazio. Por favor, digite uma mensagem.");
      return;
    }

    if (contacts.length === 0) {
      this.showModal("Nenhum contato foi importado. Por favor, use o botão 'Upload' para adicionar seu template.");
      return;
    }

    const message = draftToHtml(convertToRaw(content));
    const formattedMessage = this.substituirMarcacoes(message);

    // Enviar mensagens para contatos
    const updatedContacts = await Promise.all(
      contacts.map(async (contact) => {
        try {
          const response = await this.sendMessage(contact.telefone, formattedMessage);
          return {
            ...contact,
            status: response.success,
            message: response.message || 'Erro desconhecido'
          };
        } catch (error) {
          return {
            ...contact,
            status: false,
            message: error.message || 'Erro ao enviar mensagem'
          };
        }
      })
    );

    if (this._isMounted) {
      this.setState({
        contacts: updatedContacts,
        showGrid: true
      });
    }
  };

  showModal = (message) => {
    this.setState({ showModal: true, modalMessage: message });
  };

  closeModal = () => {
    this.setState({ showModal: false, modalMessage: '' });
  };

  async sendMessage(to, message) {
   
    const endpoint = process.env.REACT_APP_ENDPOINT; 
    const token = process.env.REACT_APP_TOKEN; 
    const uid = process.env.REACT_APP_UID; 
    const custom_uid = generateGUID();

    const url = `${endpoint}?token=${token}&uid=${uid}&to=${to}&custom_uid=${custom_uid}&text=${encodeURIComponent(message)}`;

    try {
      const response = await axios.post(url);
      return { success: response.data.success, message: response.data.message };
    } catch (error) {
      throw new Error(error.message || 'Erro ao enviar mensagem');
    }
  }

  ExcelDownloadButton = () => {
    const data = [{ Nome: '', Telefone: '' }];
    const worksheet = XLSX.utils.json_to_sheet(data);

    const phoneColumn = 'B';
    for (let i = 0; i < data.length; i++) {
      const cellAddress = `${phoneColumn}${i + 2}`;
      worksheet[cellAddress].z = '0';
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const fileName = 'Template.xlsx';

    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  toggleGrid = () => {
    this.setState({
      contacts: [],
      editorState: EditorState.createEmpty(),
      showGrid: false
    });
  };

  render() {
    const { editorState, contacts, showGrid, showModal, modalMessage } = this.state;

    return (
      <div className="container">
        <div><img src={logo} alt="Logo" className="top-image" /></div>
        <h1 className="title">Envio do Comercial</h1>
        <div className="editorBox">
          <Editor
            editorState={editorState}
            wrapperClassName="rich-editor demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={this.onEditorStateChange}
            placeholder="Digite sua mensagem aqui..."
            ref={this.editorRef}
            onKeyDown={this.handleKeyDown}
            toolbar={{
              link: { options: ['link', 'unlink'] },
            }}
          />
          <div className="button-container">
            <button
              className="btn btn-outline-secondary"
              title="Faça o download do template aqui.."
              onClick={this.ExcelDownloadButton}
            >
              Baixar <BsFillFileEarmarkArrowDownFill />
            </button>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={this.handleFileUpload}
              style={{ display: 'none' }}
              ref={input => this.fileInput = input}
            />
            <button
              className="btn btn-outline-warning"
              title="Importe sua planilha de contatos aqui.."
              onClick={() => this.fileInput.click()}
            >
              Upload <BsFillFileEarmarkArrowUpFill />
            </button>
            <button
              className="btn btn-outline-success"
              title="Clique aqui para enviar sua mensagem pelo Whatsapp."
              onClick={this.sendMessagesToContacts}
            >
              Enviar <BsFillSendFill />
            </button>
          </div>
        </div>

        {showGrid && (
          <div className="gridview">
            <button className="close-button" onClick={this.toggleGrid}>×</button>
            <h2>Resultado dos Envios</h2>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Telefone</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.map((contact, index) => (
                    <tr key={index}>
                      <td>{contact.nome}</td>
                      <td>{contact.telefone}</td>
                      <td>
                        <button
                          title={contact.status ? 'Enviado com sucesso' : contact.message} 
                          style={{ backgroundColor: contact.status ? 'green' : 'red', color: 'white' }}
                        >
                          {contact.status ? 'Sim' : 'Não'}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Modal de alerta */}
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={this.closeModal}>&times;</span>
              <p>{modalMessage}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export { MyEditor };